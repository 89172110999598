.bulk-enrollment {
  height: 100%;
  display: flex;
  flex-direction: column;

  .alert {
    margin-top: 2rem;
  }
  .enroll-button {
    padding-left: 0;
  }
}

.be-review-list {
  list-style-type: none;
  margin: 0;
  padding: 0;

  .card {
    @extend .mb-1;
  }

  .card-body {
    @extend .py-2;
    @extend .px-3;
  }

  .list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

@include media-breakpoint-down(md) {
  .be-review-list {
    display: flex;
  }
  .row {
    display: flex;
    flex-direction: column;
  }
}

.sticky-footer-wrapper {
  height: 80vh;
  display: flex;
  flex-direction: column;
}

.cell-max-width-override {
  max-width: unset !important;
}

.data-table-selector-column-wrapper {
  table {
    thead {
      th:first-of-type {
        width: 50px;
      }
    }
  }
}

.bulk-enroll-modal {
  min-height: 83vh;
}
