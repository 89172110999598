header {
  .navbar-brand {
    img {
      max-height: 60px;
      max-width: 100px;
      width: auto;
    }
  }

  .user-profile-img {
    width: 32px;
  }
}
