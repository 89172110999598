footer {
  .logo-links {
    display: flex;
    align-items: center;

    .logo {
      padding: 0 1.5rem;

      img {
        max-height: 60px;
        max-width: 100px;
        width: auto;
      }
    }
  }

  .footer-links {
    min-width: 410px;
  }
}

// xs
@media (max-width: 767px) {
  footer {
    .nav {
      justify-content: flex-start !important;
      margin: 20px 0 0;
    }
  }
}
