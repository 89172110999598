$viewport-size-details-overlay: 'md';
$number-card-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);

.number-card {
  box-shadow: $number-card-box-shadow;
  position: relative;

  &.has-details {
    .card {
      border-bottom: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  @include media-breakpoint-up($viewport-size-details-overlay) {
    flex: 1 1 auto;

    &.has-details {
      margin-bottom: 53px; // height of collapsed .card-footer
    }
  }

  .card-title {
    font-size: 2.5rem;
    font-weight: 500;
    font-family: $font-family-monospace;
  }

  .card-body {
    .fa {
      background: $info-100;
      color: $info-700;
      font-size: $font-size-lg;
      border-radius: 100%;
      width: 3.75rem;
      height: 3.75rem;
    }
  }

  .card-footer {
    position: relative;
    font-size: $font-size-sm;
    background: $white;
    padding: 0;
    border: $card-border-width solid $card-border-color;
    @include border-radius($card-border-radius);

    @include media-breakpoint-up($viewport-size-details-overlay) {
      & {
        position: absolute;
        z-index: $zindex-dropdown;
        top: 100%;
        width: 100%;
        box-shadow: $number-card-box-shadow;
      }
    }

    .toggle-collapse {
      padding: 1rem 1.25rem;
      font-weight: 600;
      color: $dark-700;
      font-size: $font-size-sm;
      text-decoration: none;

      .fa {
        font-size: 1rem;
      }

      &:hover,
      &:focus {
        .details-btn-text {
          text-decoration: underline;
        }
      }
    }

    .footer-body {
      .btn {
        display: block;
        width: 100%;
        font-size: $font-size-sm;
        padding: 0.5rem 1.25rem;
        text-align: left;
        text-decoration: none;
        border-radius: 0;
        justify-content: space-between;
        align-content: center;

        &:hover {
          background: $info-100;
        }

        &:focus {
          box-shadow: $input-btn-focus-box-shadow;
        }

        &.active {
          background: $info-100;
        }

        span.label {
          white-space: normal;
        }
      }
    }
  }
}
