@import "~@edx/brand/paragon/variables";

.lms-card-content .pgn__card-header-content {
  flex-grow: 1 !important;
}

.lms-icon {
  max-width: 35px;
  max-height: 35px;
}

.sso-error-lms-icon {
  max-width: 71px;
  max-height: 71px;
}

.sso-alert-width {
  width: 73% !important;
}

.lms-card-hover {
  &:hover {
    box-shadow: $box-shadow;
    cursor: pointer;
  }

  &:focus,
  &.focus {
    box-shadow: $box-shadow;
    outline: none;
    border-color: $dark-300;
  }
}

.lms-card {
  width: 22rem !important;
  box-shadow: $box-shadow-sm;

  &:hover {
    box-shadow: $box-shadow;
    cursor: pointer;
  }

  &:focus,
  &.focus {
    box-shadow: $box-shadow;
    outline: none;
    border-color: $dark-300;
  }
}

.button-link {
  text-decoration: none;
  color: $white;
  &:hover {
    text-decoration: none;
    color: $white;
  }
}

.side-button {
  float: right;
  margin-left: 1rem;
  margin-right: 1rem;
  right: 0;
  width: 300px;
}

.sso-alert-modal-margin {
  margin-right: 15% !important;
}

.lms-card-title-overflow {
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  white-space: nowrap;
}

.existing-lms-card {
  width: 70rem !important;
  .pgn__card-header .pgn__card-header-content {
    margin-top: 0 !important;
    justify-content: center;
  }
}


.sso-create-form {
  width: 65vw;
}

.sso-create-form-control {
  width: 100%;
}

.sso-connect-step-copy-form > .form-control {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.sso-connect-step-copy-button {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.sso-connect-success-icon {
  color: green;
}

.image-preview {
  display: flex;
  justify-content: center;
  padding: 1rem;
  border: 1px dashed $gray-500;
  min-height: 200px;
  align-items: center;
}

.image-preview img {
    max-height: 150px;
    max-width: 300px;
    width: auto;
}

.small-icon {
  height: 15px;
  width: 15px;
  margin: 0 4px 0 4px;
}

.pgn__tabs .pgn__tab-notification {
  min-height: 0.75rem;
  min-width: 0.75rem;
}

.no-config {
  max-height: 12rem !important;
  .pgn__card-image-cap {
    padding: 1rem;
    object-fit: fill;
  }
}

.error-footer {
  background: $danger-100;
  display: block;
  text-align: center;
  padding: 1rem 9rem !important;
  .error-icon {
    color: $danger-600;
  }
}

.select-lms-card {
  height: 6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22rem;
  padding-top: 1rem;
}

.card-status-badge {
  margin-left: 0.5rem;
  vertical-align: middle;
  font-size: 1rem; 
  font-weight: 500;
}

.warning-icon {
  color: #F0CC00;
}

.api-cred-fields {
  span {
    font-weight: normal;
    margin-left: 0.25rem;
  }
}
