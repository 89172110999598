.table {
  font-size: $font-size-sm;

  th.sortable > button {
    padding-left: 0;
    font-size: $font-size-sm;
    font-weight: 600;
    text-align: left;
    background: none;
  }
}
