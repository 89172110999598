// ... Any custom SCSS variables should be defined here
$modal-max-width: 650px;

// Paragon/Brand
@import "~@edx/brand/paragon/fonts";
@import "~@edx/brand/paragon/variables";
@import "~@openedx/paragon/scss/core/core";
@import "~@edx/frontend-enterprise-catalog-search";
@import "~@edx/brand/paragon/overrides";

// Components
@import "./components/EnterpriseApp/EnterpriseApp";
@import "./components/CodeSearchResults/CodeSearchResults";
@import "./components/Coupon/Coupon";
@import "./components/CouponDetails/CouponDetails";
@import "./components/FileInput/FileInput";
@import "./components/NumberCard/NumberCard";
@import "./components/RequestCodesPage/RequestCodesPage";
@import "./components/Sidebar/Sidebar";
@import "./components/subscriptions/styles";
@import "./components/LoadingMessage/LoadingMessage";
@import "./components/TableComponent/TableComponent";
@import "./components/TableLoadingOverlay/TableLoadingOverlay";
@import "./components/Hero/Hero";
@import "./components/BulkEnrollmentPage/BulkEnrollment";
@import "./components/Admin/Admin";
@import "./components/settings/settings";
@import "./components/learner-credit-management/styles";
@import "./components/AdvanceAnalyticsV2/styles";
@import "./components/PeopleManagement/PeopleManagement";

// Global
body {
  overflow-x: hidden;
}

#root #app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

div.enterprise-app {
  flex: 1;
}

footer {
  flex: none;
}

form {
  label {
    .required {
      color: $danger;
      margin-left: 4px;
    }
  }

  textarea {
    resize: none;
  }
}

.container-fluid {
  width: 100%;
  max-width: none;
  padding-left: 15px;
  padding-right: 15px;
}

.toast-container {
  z-index: $zindex-popover;
}

.modal {
  .modal-dialog {
    max-width: $modal-max-width;
  }
}

.stepper-modal .pgn__modal-header {
  border-bottom: solid 7px;
}

.font-size-base {
  font-size: $font-size-base !important;
}

// TODO: Class override can be removed once Paragon is upgraded to version v22.8.1
.pgn__card-logo-cap {
  object-fit: scale-down !important;
  object-position: center center !important;
}
