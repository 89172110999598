.code-search-results {
  .loading {
    @extend .mb-3;
  }
  .table {
    @extend .bg-white;
    td {
      vertical-align: middle;
    }
  }
  .pagination {
    @extend .mb-0;
    button {
      font-size: $font-size-sm;
    }
  }
}
