.coupon-details {
  font-size: 1rem;

  .loading {
    font-size: inherit;
  }

  .alert {
    margin-bottom: 0;

    &,
    .btn-link {
      font-size: $font-size-sm;
    }

    .btn-link {
      text-decoration: underline;
      margin-top: -4px;
    }

    .fa {
      font-size: 1rem;
    }
  }

  .table,
  .pagination .btn {
    font-size: $font-size-sm;
  }

  .table {
    td {
      vertical-align: middle;
    }

    .btn {
      font-size: inherit;
      text-decoration: underline;

      &:focus {
        box-shadow: $btn-focus-box-shadow;
      }
    }

    .form-check {
      padding-left: 0;

      input[type='checkbox'] {
        border: 0;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;

        & + label {
          width: 15px;


        }


      }
    }
  }

  .toggles,
  .bulk-actions {
    .form-group {
      display: inline-block;

      &,
      label {
        margin: 0;
      }

      label {
        font-weight: 600;
      }
    }
  }
}
