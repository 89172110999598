.modal-dialog.code-assignment {
  .modal-title {
    span {
      font-weight: 600;
    }
  }

  .modal-body {
    .field-group {
      p {
        font-weight: 600;
      }
    }

    p {
      margin-bottom: 6px;
    }
  }
}
