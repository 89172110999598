.manage-subscription {
    display: flex;
    flex-direction: column;
    flex: 1;

    .add-users-dropdown {
        .dropdown-menu {
            z-index: $zindex-fixed;
        }
    }

    .subscription-card {
        margin-bottom: map_get($spacers, 4);
    }

    @include media-breakpoint-up(lg) {
        .add-users-dropdown {
            float: right;

            .dropdown-menu {
                left: inherit;
                right: 0;
            }
        }
    }
}

.pgn__modal-title .enroll-header {
    display: inline-block;
    background-color: transparent;
    color: $red;
}
