.enterprise-app {
  position: relative;
  display: flex;

  .content-wrapper {
    padding: 10px;

    @include media-breakpoint-up(lg) {
      & {
        // If we change the width of the icon-only menu links in the Sidebar navigation,
        // we will also need to update the padding-left to ensure correct spacing.
        padding-left: 51px;
      }
    }
  }
  .full-page {
    flex: 1;
  }
}
