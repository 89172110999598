.table-loading-overlay {
  position: absolute;
  z-index: 10;
  background: $black;
  opacity: 0.2;
  height: 100%;
  width: 100%;
}

.table-loading-message {
  position: absolute;
  z-index: 11;
  width: 100%;
  height: 100%;

  span {
    padding: 20px;
  }
}
