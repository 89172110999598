.coupon {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  transition: opacity 0.2s ease;

  &.dimmed {
    opacity: 0.5;

    &:hover,
    &:focus {
      opacity: 1;
    }
  }

  &:not(.expanded):not(.border-danger) {
    &:hover,
    &:focus {
      border-color: $gray-300 !important; // `!important` needed to override .border Bootstrap class
    }
  }

  &.border-danger {
    .metadata {
      background: $danger-100;

      &:hover,
      &:focus {
        background: $danger-200;
      }
    }
  }

  .metadata {
    transition: background 0.2s ease;
    position: relative;

    &:hover,
    &:focus {
      background: $gray-100;
      cursor: pointer;
    }

    .icons {
      position: absolute;
      top: 0;
      right: 0;

      @include media-breakpoint-up(lg) {
        position: relative;
      }
    }
  }

  &.expanded {
    &:hover,
    &:focus {
      background: inherit;
    }

    .metadata {
      background: $info-900;
      border-radius: 0.1875rem 0.1875rem 0 0;
      color: $white;
    }
  }
}

