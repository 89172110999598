.analytics-stat-number {
    font-size: 2.5rem;
}

@mixin fetching-overlay {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($white, 0.7);
    z-index: 1;
}

@mixin spinner-centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
}

.analytics-chart-container,
.stats-container {
    position: relative;

    &.is-fetching::before {
        @include fetching-overlay;
    }

    .spinner-centered {
        @include spinner-centered;
    }
}
.analytics-chart-container{
    min-height: 40vh;
}

