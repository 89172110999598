.sidebar {
  background: $gray-100;
  position: absolute;
  top: 0;
  left: 0;
  z-index: $zindex-fixed;

  &.expanded {
    width: auto;

    &.has-shadow {
      @include pgn-box-shadow(1, 'right');
    }
  }

  &:not(.expanded) {
    .nav-link {
      // TODO: using a hardcoded pixel value isn't ideal, but it's a temporary fix while we have
      // both Font Awesome and Paragon icons in the sidebar navigation, each having slightly different
      // styles and DOM structures, making styling difficult. 44px is the height of each nav link when
      // the sidebar is expanded, so this forces it to be the same height when not expanded as well.
      height: 44px;
    }
  }

  .sidebar-content {
    .nav {
      position: sticky;
      top: 0;

      .nav-link {
        color: $gray-700;

        &.active {
          background: $primary;
          color: $white;
        }

        &:not(.active):hover,
        &:not(.active):focus {
          background: $gray-200;
        }
      }
    }
  }
}
