.hero {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 8rem;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: solid 7px;

  h1 {
    margin: 0
  }

  img {
    max-width: 90px;
  }
}
