.loading {
  background: $info-100;
  font-size: $font-size-lg;

  &.table-loading,
  &.request-codes,
  &.support,
  &.admin-register,
  &.user-activation,
  &.subscriptions,
  &.settings {
    height: 360px;
  }

  &.overview,
  &.coupons {
    height: 180px;
  }
}
